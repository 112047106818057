/* Container for the language dropdown */
.language-dropdown-container {
    width: 170px;
}

/* Custom option styling */
.custom-option {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}
    
/* Flag image in the dropdown */
.flag-image {
    width: 20px;
    margin-right: 10px;
}

.language-select {
    width: 170px !important;
    color: var(--text-secondary) !important;
}

/* React-select main control styling */
.language-select .language-select__control {
    border: none;
    box-shadow: 0 0 5px 2px #E7F5F8; /* Custom shadow color */
    outline: none;
    border-radius: 15px !important;
}

.language-select .language-select__control:hover {
    border: none;
    cursor: pointer;
}

/* React-select menu (dropdown) styling */
.language-select .language-select__menu {
    outline: none;
    border: none;
    width: 170px !important;
    border-radius: 15px !important;
    z-index: 1000;
}

.language-select .language-select__menu:hover {
    cursor: pointer;
}

/* React-select option styling */
.language-select .language-select__option {
    font-size: 0.9em;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    border-radius: 15px !important;
    cursor: pointer !important;
}

.language-select .language-select__option--is-selected {
    background-color: white;
    font-size: 0.9em;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    border-radius: 15px !important;
    color: var(--text-secondary) !important;
}
.language-select .language-select__option--is-focused {
    background-color: #f5f5f5 !important;
}


.language-select .language-select__option:hover {
    background-color: #f5f5f5 !important;
    cursor: pointer !important;
}

/* React-select single value styling */
.language-select .language-select__single-value {
    font-size: 0.9em;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}

/* Input text styling */
.language-select .language-select__input input {
    font-size: 0.9em;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    color: #19A4BB; /* Custom primary color */
}

/* Input text styling */
.language-select .language-select__value-container {
    font-size: 0.9em;
    font-family: "Montserrat", sans-serif;
    color: var(--text-secondary) !important;
    font-weight: 500;
    padding-left: 10px;
    display: flex;
    align-items: center;
}

/* Dropdown indicator (arrow) styling */
.language-select .language-select__dropdown-indicator {
    color: #47b6c8; /* Custom primary_light color */
}

.language-select .language-select__dropdown-indicator:hover {
    color: #19A4BB; /* Custom primary color on hover */
}
